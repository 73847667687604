import {TextFontFamilies, TextFontWeights} from "../../constants/element-property-values";

/**
 * Utilities of the Text's Font properties.
 */
class TextFontUtils {
    /**
     * The default font family of the Text Elements in the application.
     */
    public static defaultFontFamily = TextFontFamilies.inter;
    /**
     * The default font weight of the Text Elements in the application
     */
    public static defaultFontWeight = TextFontWeights.regular;

    /**
     * The applicable font weights of the application for each font family.
     * @private
     */
    private static applicableFontWeights = {
        [TextFontFamilies.lora]: [
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
        ],
        [TextFontFamilies.leagueGothic]: [
            TextFontWeights.regular,
        ],
        [TextFontFamilies.raleway]: [
            TextFontWeights.thin,
            TextFontWeights.extraLight,
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
            TextFontWeights.black,
        ],
        [TextFontFamilies.exo2]: [
            TextFontWeights.thin,
            TextFontWeights.extraLight,
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
            TextFontWeights.black,
        ],
        [TextFontFamilies.merriweatherSans]: [
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
        ],
        [TextFontFamilies.openSans]: [
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
        ],
        [TextFontFamilies.robotoFlex]: [
            TextFontWeights.thin,
            TextFontWeights.extraLight,
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
            TextFontWeights.black,
        ],
        [TextFontFamilies.montserrat]: [
            TextFontWeights.thin,
            TextFontWeights.extraLight,
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
            TextFontWeights.black,
        ],
        [TextFontFamilies.inter]: [
            TextFontWeights.thin,
            TextFontWeights.extraLight,
            TextFontWeights.light,
            TextFontWeights.regular,
            TextFontWeights.medium,
            TextFontWeights.semiBold,
            TextFontWeights.bold,
            TextFontWeights.extraBold,
            TextFontWeights.black,
        ],
    }

    /**
     * Fetches the title of the given font weight.
     * @param weight
     */
    static getWeightTitle(weight: TextFontWeights): string {
        switch (weight as TextFontWeights) {
            case TextFontWeights.thin:
                return "Thin";
            case TextFontWeights.extraLight:
                return "Extra Light";
            case TextFontWeights.light:
                return "Light";
            case TextFontWeights.regular:
                return "Regular";
            case TextFontWeights.medium:
                return "Medium";
            case TextFontWeights.semiBold:
                return "Semi-Bold";
            case TextFontWeights.bold:
                return "Bold";
            case TextFontWeights.extraBold:
                return "Extra Bold";
            case TextFontWeights.black:
                return "Black";
            default:
                return "Unknown";
        }
    }

    /**
     * Determines whether the given family is a supported font-family.
     * @param family
     */
    static familySupported(family: string): boolean {
        return Object.values(TextFontFamilies).includes(family as TextFontFamilies);
    }

    /**
     * Fetches the applicable font weights of the given font family.
     * @param family
     */
    static getApplicableFamilyWeights(family: string): Array<number> {
        return (this.applicableFontWeights[family as TextFontFamilies]) ?? [];
    }
}

export default TextFontUtils;
