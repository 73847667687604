import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {usePointerLock} from "../../../hooks/use-pointer-lock";

const initPosition = {top: 0, left: 0};

const PointerLockCursor = () => {
    const {cursorUrl} = usePointerLock();
    const [position, setPosition] = useState(initPosition);
    const [target, setTarget] = useState(null);
    /**@type {React.MutableRefObject<HTMLImageElement>}*/
    const cursorRef = useRef();

    const show = useMemo(() => !!target, [target]);

    /**
     * Moves the fake-cursor with the movement of the locked-pointer.
     * @type {(function(MouseEvent | TouchEvent): void)}
     */
    const moveCursor = useCallback((e) => {
        const cursorRect = cursorRef.current.getBoundingClientRect();
        setPosition(prevState => {
            let x = prevState.left;
            let y = prevState.top;
            x += e.movementX;
            y += e.movementY;
            if (x > window.innerWidth + (cursorRect.width / 2)) {
                x = -(cursorRect.width / 2);
            }
            if (y > window.innerHeight + (cursorRect.height / 2)) {
                y = -(cursorRect.height / 2);
            }
            if (x < -(cursorRect.width / 2)) {
                x = window.innerWidth + (cursorRect.width / 2);
            }
            if (y < -(cursorRect.height / 2)) {
                y = window.innerHeight + (cursorRect.height / 2);
            }
            return {top: y, left: x}
        })
    }, [cursorRef])

    /**
     * Determines whether the locked-pointer is this PointerLock or not and stores the value in the [locked] state.
     * @type {(function(): void)}
     */
    const onPointerLockChanged = useCallback(() => {
        const pointerLockElement = document.pointerLockElement || document.mozPointerLockElement || document.webkitPointerLockElement;
        setTarget(pointerLockElement);
    }, [])

    /**
     * As soon as the component mounts:
     * - attaches an event listener for the [pointerlockchange] event of the document that determines whether the locked-pointer is this
     * PointerLock or not
     */
    useLayoutEffect(() => {
        document.addEventListener('pointerlockchange', onPointerLockChanged, false)
        return () => {
            document.removeEventListener('pointerlockchange', onPointerLockChanged, false)
        }
    }, [onPointerLockChanged])

    /**
     * With each change in the value of the [locked] state:
     * - attaches or removes event listeners for moving the cursor.
     */
    useEffect(() => {
        if (!target)
            return
        const pointerLockElementRect = target.getBoundingClientRect();
        setPosition({
            left: pointerLockElementRect.left,
            top: pointerLockElementRect.top,
        })
        document.addEventListener("mousemove", moveCursor, false);
        document.addEventListener("touchmove", moveCursor, false);
        return () => {
            document.removeEventListener("mousemove", moveCursor, false);
            document.removeEventListener("touchmove", moveCursor, false);
            setPosition(initPosition);
        };
    }, [moveCursor, target])

    /**@type {CSSProperties}*/
    const cursorStyles = useMemo(() => {
        if (!show) {
            return {display: 'none'}
        }
        return {
            zIndex: 100,
            transform: `translate(calc(-25% + ${position.left + 'px'}), calc(25% + ${position.top + 'px'}))`,
            position: 'fixed',
            width: 20,
            height: 20,
            top: 0,
            left: 0,
        }
    }, [position, show])

    return (
        <>
            <img
                ref={cursorRef}
                src={cursorUrl}
                style={cursorStyles}
                alt={"fake-cursor"}
            />
        </>
    )
}

export default PointerLockCursor;
