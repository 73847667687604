import {experimental_extendTheme as extendMuiTheme} from "@mui/material/styles";
import colors from "@mui/joy/colors";
import {
    AppFontFamilyTheme,
    AppSpecificThemeScalesTheme,
    DarkAppSpecificPaletteTheme,
    LightAppSpecificPaletteTheme,
    ThemePrefix
} from "../shared";
import {toggleButtonClasses} from "@mui/material";

const muiTheme = extendMuiTheme({
    cssVarPrefix: ThemePrefix,
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: colors.blue[500],
                },
                secondary: {
                    main: '#fc9d07',
                },
                grey: colors.grey,
                error: {
                    main: colors.red[500],
                },
                info: {
                    main: colors.purple[500],
                },
                success: {
                    main: colors.green[500],
                },
                warning: {
                    main: colors.yellow[200],
                },
                common: {
                    white: '#FFF',
                    black: '#09090D',
                },
                divider: colors.grey[200],
                text: {
                    primary: colors.grey[800],
                    secondary: colors.grey[600],
                },
                appSpecific: LightAppSpecificPaletteTheme,
            },
        },
        dark: {
            palette: {
                primary: {
                    main: colors.blue[600],
                },
                secondary: {
                    main: '#fc9d07',
                },
                grey: colors.grey,
                error: {
                    main: colors.red[600],
                },
                info: {
                    main: colors.purple[600],
                },
                success: {
                    main: colors.green[600],
                },
                warning: {
                    main: colors.yellow[300],
                },
                common: {
                    white: '#FFF',
                    black: '#09090D',
                },
                divider: colors.grey[800],
                text: {
                    primary: colors.grey[100],
                    secondary: colors.grey[300],
                },
                appSpecific: DarkAppSpecificPaletteTheme,
            },
        },
    },
    appSpecific: AppSpecificThemeScalesTheme,
    typography: {
        fontFamily: AppFontFamilyTheme.body,
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                enterDelay: 1500,
                arrow: true,
                disableInteractive: true,
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: ({theme}) => ({
                    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2,' +
                        ' 1) 0ms;',
                    [`&:hover`]: {
                        boxShadow: `inset 0 0 0 1px ${theme.vars.palette.divider}`,

                    },
                    [`&.readonly`]: {
                        [`&:hover`]: {
                            boxShadow: 'none',
                        },
                    },
                }),
            },
        },
        MuiToggleButton: {
            defaultProps: {
                size: 'small',
                disableRipple: true,
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    border: 'none',
                    borderRadius: `${theme.shape.borderRadius}px !important`,
                    [`&:hover`]: {
                        backgroundColor: 'transparent',
                    },
                    [`&.${toggleButtonClasses.selected}`]: {
                        backgroundColor: theme.vars.palette.divider,
                        [`&:hover`]: {
                            backgroundColor: theme.vars.palette.divider,
                        },
                    },
                    [`&.${toggleButtonClasses.disabled}`]: {
                        border: 'none'
                    },
                }),
            },
        },
    },
});

export default muiTheme;
