import {ModalUnstyledOwnProps} from "@mui/base/ModalUnstyled";
import {
    AddPrintedBlankPagesDialogServiceResponseData,
    DialogServiceCloseReason,
    NewAveryLabelElementDialogServiceResponseData
} from "../../../services/dialog";
import {ModalDialogTypeMap} from "@mui/joy/ModalDialog/ModalDialogProps";
import {PrintQuestionnaire} from "../../../in-app/print-configurable";
import {IPageSize} from "../../../in-app/page-size";

//          ###########################      Dialogs       ###########################

type DialogProps =
    Required<Pick<ModalUnstyledOwnProps, 'disableAutoFocus' | 'disableEnforceFocus' | 'disableEscapeKeyDown' | 'disablePortal' | 'disableRestoreFocus' | 'disableScrollLock' | 'hideBackdrop' | 'keepMounted' | 'open'>>
    & Required<Pick<ModalDialogTypeMap['props'], 'layout' | 'variant' | 'color' | 'size'>>;

export interface DialogsReduxSliceStateBaseEntry<T = any> extends DialogProps {
    onClose: ((reason: DialogServiceCloseReason, data?: any) => void),
    disableBackdropClick: boolean,
    hideCloseButton: boolean,
    data?: T
}

export enum DialogsReduxSliceStateActionTypes {
    newAveryLabelElement = 'newAveryLabelElement',
    printQuestionnaire = 'printQuestionnaire',
    addPrintedBlankPages = 'addPrintedBlankPages',
    movePrintedPage = 'movePrintedPage',
    confirmation = 'confirmation',
}

export interface DialogsReduxSliceState {
    [DialogsReduxSliceStateActionTypes.newAveryLabelElement]: DialogsReduxSliceStateNewAveryLabelElementEntry,
    [DialogsReduxSliceStateActionTypes.printQuestionnaire]: DialogsReduxSliceStatePrintQuestionnaireEntry,
    [DialogsReduxSliceStateActionTypes.addPrintedBlankPages]: DialogsReduxSliceStateAddBlankPagesEntry,
    [DialogsReduxSliceStateActionTypes.movePrintedPage]: DialogsReduxSliceStateMovePrintedPageEntry,
    [DialogsReduxSliceStateActionTypes.confirmation]: DialogsReduxSliceStateConfirmationEntry,
}


//          -----------------------------      New Avery Label Element       -----------------------------

export interface DialogsReduxSliceStateNewAveryLabelElementEntryData {
    showNewPageCreation: boolean,
    showChangePageSize: boolean,
}

export interface DialogsReduxSliceStateNewAveryLabelElementEntry extends DialogsReduxSliceStateBaseEntry<DialogsReduxSliceStateNewAveryLabelElementEntryData> {
    onClose: ((reason: DialogServiceCloseReason, data: NewAveryLabelElementDialogServiceResponseData) => void),
}


//         -----------------------------      Print Questionnaire       -----------------------------

export interface DialogsReduxSliceStatePrintQuestionnaireEntryData {
    questionnaire: PrintQuestionnaire,
}


export interface DialogsReduxSliceStatePrintQuestionnaireEntry extends DialogsReduxSliceStateBaseEntry<DialogsReduxSliceStatePrintQuestionnaireEntryData> {
    onClose: ((reason: DialogServiceCloseReason, data: void) => void),
}

//          -----------------------------      Add Blank Pages       -----------------------------


export interface DialogsReduxSliceStateAddBlankPagesEntryData {
    pageSize: IPageSize,
}

export interface DialogsReduxSliceStateAddBlankPagesEntry extends DialogsReduxSliceStateBaseEntry<DialogsReduxSliceStateAddBlankPagesEntryData> {
    onClose: ((reason: DialogServiceCloseReason, data: AddPrintedBlankPagesDialogServiceResponseData) => void),
}

//          -----------------------------      Move Printed Page       -----------------------------

export interface DialogsReduxSliceStateMovePrintedPageEntryData {
    currentIndex: number,
    totalPages: number,
}


export interface DialogsReduxSliceStateMovePrintedPageEntry extends DialogsReduxSliceStateBaseEntry<DialogsReduxSliceStateMovePrintedPageEntryData> {
    onClose: ((reason: DialogServiceCloseReason, data: number) => void),
}


export interface DialogsReduxSliceStateConfirmationEntryData {
    title?: string,
    message?: string,
    confirmText?: string,
    declineText?: string,
}

export interface DialogsReduxSliceStateConfirmationEntry extends DialogsReduxSliceStateBaseEntry<DialogsReduxSliceStateConfirmationEntryData> {
    onClose: ((reason: DialogServiceCloseReason, data: boolean) => void),
}
