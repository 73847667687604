import {createContext, useContext} from "react";


export interface IPointerLockContext {
    cursorUrl?: string;
}

export type IPointerLockMutator = (options: IPointerLockContext) => void;

export interface IPointerLockControllerContext {
    setLock: IPointerLockMutator;
}

export const PointerLockContext = createContext<IPointerLockContext>({});
export const PointerLockControllerContext = createContext<IPointerLockControllerContext>({
    setLock: () => void 0,
});

/**
 * Provides convenient access to the controller of the pointer lock.
 */
export const usePointerLockController = () => {
    return useContext(PointerLockControllerContext);
}

/**
 * Provides convenient access to the state of the pointer lock.
 * */
export const usePointerLock = () => {
    return useContext(PointerLockContext);
}
