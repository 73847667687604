import React, {lazy, Suspense, useCallback, useEffect, useLayoutEffect, useRef} from "react";
import ElementIds from "../../core/models/static/element-ids";
import LoadingBar from "react-top-loading-bar";

const ApplicationRoutes = lazy(() => import("../routes"));
const ApplicationDialogs = lazy(() => import('../dialogs'));

const ApplicationViews = () => {
    /**@type {React.MutableRefObject<import('react-top-loading-bar').LoadingBarRef>}*/
    const loadingBarRef = useRef();

    /**
     * Starts the animation of the LoadingBar component.
     */
    const onRoutesLoadingStarted = useCallback(() => {
        loadingBarRef.current?.continuousStart?.(0, 300);
    }, [loadingBarRef])

    /**
     * Completes the animation of the LoadingBar component.
     */
    const onRoutesLoadingFinished = useCallback(() => {
        loadingBarRef.current?.complete?.();
    }, [loadingBarRef])

    return (
        <div className={'app-views'}>
            <div id={ElementIds.appId} className={'app'}>
                <LoadingBar
                    ref={loadingBarRef}
                    containerClassName={'loading-bar-container'}
                    className={'loading-bar'}
                    color={'transparent'}
                />
                <Suspense
                    fallback={
                        <RoutesLoading
                            onStart={onRoutesLoadingStarted}
                            onFinish={onRoutesLoadingFinished}
                        />
                    }
                >
                    <ApplicationRoutes/>
                    <ApplicationDialogs/>
                </Suspense>
            </div>
        </div>
    );
}

const RoutesLoading = ({onStart, onFinish}) => {

    /**
     * As soon as the component mounts:
     * - starts the loading bar's animations.
     */
    useLayoutEffect(() => {
        onStart();
    }, [onStart])

    /**
     * As soon as the component un-mounts:
     * - completes the loading bar's animations.
     */
    useEffect(() => () => {
        onFinish();
    }, [onFinish])

    return <></>;
}

export default ApplicationViews;
