import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DialogsReduxSliceState,
    DialogsReduxSliceStateActionTypes,
    DialogsReduxSliceStateAddBlankPagesEntry,
    DialogsReduxSliceStateBaseEntry,
    DialogsReduxSliceStateConfirmationEntry,
    DialogsReduxSliceStateMovePrintedPageEntry,
    DialogsReduxSliceStateNewAveryLabelElementEntry,
    DialogsReduxSliceStatePrintQuestionnaireEntry
} from "../../../../types/ui/redux/slices";

const baseInitialState: DialogsReduxSliceStateBaseEntry = {
    open: false,
    disableAutoFocus: false,
    disableEnforceFocus: false,
    disableEscapeKeyDown: false,
    disableBackdropClick: false,
    hideCloseButton: false,
    disablePortal: false,
    disableRestoreFocus: false,
    disableScrollLock: false,
    hideBackdrop: false,
    keepMounted: false,
    onClose: (): void => void 0,
    color: 'neutral',
    layout: 'center',
    size: 'lg',
    variant: "plain",
}

const initialState: DialogsReduxSliceState = {
    [DialogsReduxSliceStateActionTypes.newAveryLabelElement]: {
        ...baseInitialState,
        data: {
            showChangePageSize: false,
            showNewPageCreation: false,
        },
    },
    [DialogsReduxSliceStateActionTypes.printQuestionnaire]: {
        ...baseInitialState,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        data: {
            questionnaire: [],
        },
    },
    [DialogsReduxSliceStateActionTypes.addPrintedBlankPages]: {
        ...baseInitialState,
        size: 'lg',
    },
    [DialogsReduxSliceStateActionTypes.movePrintedPage]: {
        ...baseInitialState,
        size: 'md',
        data: {
            currentIndex: 0,
            totalPages: 0,
        },
    },
    [DialogsReduxSliceStateActionTypes.confirmation]: {
        ...baseInitialState,
        size: 'sm',
    },
}

const sliceName = 'dialogs';

/**
 * Merges the given state object with the provided action.
 *
 * * if the value of [open] property of [action.payload] is set to false, resets the [type] property of the state.
 * @param state
 * @param action
 */
const mergePayloadWithState = (
    state: DialogsReduxSliceState,
    action: PayloadAction<Partial<DialogsReduxSliceStateBaseEntry>>,
): DialogsReduxSliceState => {
    const type = action.type.replace(`${sliceName}/`, '') as DialogsReduxSliceStateActionTypes;
    if (action?.payload?.open === false) {
        return {
            ...state,
            [type]: {
                ...state[type],
                ...initialState[type],
            },
        };
    }
    let data;
    switch (typeof action?.payload?.data) {
        case "object":
        case "undefined": {
            if (Array.isArray(action?.payload?.data)) {
                data = [
                    ...((initialState[type]?.data as Array<any>) ?? []),
                    ...(action?.payload?.data ?? []),
                ];
                break;
            }
            data = {
                ...(initialState[type]?.data ?? {}),
                ...(action?.payload?.data ?? {}),
            };
            break;
        }
        default:
            data = action?.payload?.data ?? initialState[type].data;
    }
    return {
        ...state,
        [type]: {
            ...state[type],
            ...action?.payload,
            data: data,
        },
    };
}

/**
 * The Redux state, action creators, types and reducers of the Dialogs slice.
 */
export const DialogsReduxSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        [DialogsReduxSliceStateActionTypes.newAveryLabelElement]: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStateNewAveryLabelElementEntry>>
        ) => mergePayloadWithState(state, action),
        [DialogsReduxSliceStateActionTypes.printQuestionnaire]: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStatePrintQuestionnaireEntry>>
        ) => mergePayloadWithState(state, action),
        [DialogsReduxSliceStateActionTypes.addPrintedBlankPages]: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStateAddBlankPagesEntry>>
        ) => mergePayloadWithState(state, action),
        [DialogsReduxSliceStateActionTypes.movePrintedPage]: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStateMovePrintedPageEntry>>
        ) => mergePayloadWithState(state, action),
        [DialogsReduxSliceStateActionTypes.confirmation]: (
            state,
            action: PayloadAction<Partial<DialogsReduxSliceStateConfirmationEntry>>
        ) => mergePayloadWithState(state, action),
    },
})

export default DialogsReduxSlice;
