import {AveryLabelSize} from "../../types/in-app";

/**
 * Supported Font weights of the Text Element
 */
export enum TextFontWeights {
    thin = 100,
    extraLight = 200,
    light = 300,
    regular = 400,
    medium = 500,
    semiBold = 600,
    bold = 700,
    extraBold = 800,
    black = 900,
}

/**
 * Supported Font families of the Text Element
 */
export enum TextFontFamilies {
    lora = 'lora',
    leagueGothic = 'League Gothic',
    raleway = 'Raleway',
    exo2 = 'Exo 2',
    merriweatherSans = 'MerriWeather Sans',
    openSans = 'Open Sans',
    robotoFlex = 'Roboto Flex',
    montserrat = 'Montserrat',
    inter = 'Inter',
}

/**
 * Supported Text alignments of the Text Element
 */
export enum TextAlignments {
    center = 'center',
    right = 'right',
    left = 'left',
}

/**
 * Supported Text decorations of the Text Element
 */
export enum TextDecorations {
    none = 'none',
    underline = 'underline',
    lineThrough = 'line-through',
}

/**
 * Supported Text transforms of the Text Element
 */
export enum TextTransforms {
    none = 'none',
    lowercase = 'lowercase',
    uppercase = 'uppercase',
}


/**
 * Supported Text positions of the Barcode Element
 */
export enum BarcodeTextPositions {
    top = 'top',
    bottom = 'bottom',
}

/**
 * Supported shapes of the Avery Label Element.
 */
export enum AveryLabelShapes {
    rectangle = 'rectangle',
    square = 'square',
    zebra = 'zebra',
}

/**
 * Supported shapes of the Avery Label Element.
 */
export const AveryLabelSizes: Record<AveryLabelShapes, Record<string, AveryLabelSize>> = {
    [AveryLabelShapes.rectangle]: {
        '215.9mm x 279.4mm': {width: 215.9, height: 279.4},
        '108mm x 279.4mm': {width: 108, height: 279.4},
        '76.2mm x 279.4mm': {width: 76.2, height: 279.4},
        '190.5mm x 254mm': {width: 190.5, height: 254},
        '177.8mm x 254mm': {width: 177.8, height: 254},
        '31.8mm x 247.7mm': {width: 31.8, height: 247.7},
        '88.9mm x 203.2mm': {width: 88.9, height: 203.2},
        '82.6mm x 196.9mm': {width: 82.6, height: 196.9},
        '215.9mm x 139.7mm': {width: 215.9, height: 139.7},
        '206.4mm x 127mm': {width: 206.4, height: 127},
        '177.8mm x 127mm': {width: 177.8, height: 127},
        '152.4mm x 127mm': {width: 152.4, height: 127},
        '88.9mm x 127mm': {width: 88.9, height: 127},
        '196.9mm x 120.7mm': {width: 196.9, height: 120.7},
        '95.3mm x 120.7mm': {width: 95.3, height: 120.7},
        '152.4mm x 101.6mm': {width: 152.4, height: 101.6},
        '127mm x 101.6mm': {width: 127, height: 101.6},
        '84.7mm x 101.6mm': {width: 84.7, height: 101.6},
        '76.2mm x 101.6mm': {width: 76.2, height: 101.6},
        '101.6mm x 84.7mm': {width: 101.6, height: 84.7},
        '212.7mm x 82.6mm': {width: 212.7, height: 82.6},
        '177.8mm x 76.2mm': {width: 177.8, height: 76.2},
        '152.4mm x 76.2mm': {width: 152.4, height: 76.2},
        '127mm x 76.2mm': {width: 127, height: 76.2},
        '95.3mm x 76.2mm': {width: 95.3, height: 76.2},
        '177.8mm x 63.5mm': {width: 177.8, height: 63.5},
        '127mm x 63.5mm': {width: 127, height: 63.5},
        '101.6mm x 63.5mm': {width: 101.6, height: 63.5},
        '85.7mm x 59.3mm': {width: 85.7, height: 59.3},
        '196.9mm x 57.2mm': {width: 196.9, height: 57.2},
        '177.8mm x 50.8mm': {width: 177.8, height: 50.8},
        '152.4mm x 50.8mm': {width: 152.4, height: 50.8},
        '127mm x 50.8mm': {width: 127, height: 50.8},
        '101.6mm x 50.8mm': {width: 101.6, height: 50.8},
        '95.3mm x 50.8mm': {width: 95.3, height: 50.8},
        '88.9mm x 50.8mm': {width: 88.9, height: 50.8},
        '76.2mm x 50.8mm': {width: 76.2, height: 50.8},
        // Default Rectangle Size
        '69.9mm x 50.8mm': {width: 69.9, height: 50.8},
        '66.7mm x 50.8mm': {width: 66.7, height: 50.8},
        '196.9mm x 44.5mm': {width: 196.9, height: 44.5},
        '190.5mm x 38.1mm': {width: 190.5, height: 38.1},
        '55.6mm x 46mm': {width: 55.6, height: 46},
        '95.3mm x 38.1mm': {width: 95.3, height: 38.1},
        '69.9mm x 38.1mm': {width: 69.9, height: 38.1},
        '101.6mm x 33.9mm': {width: 101.6, height: 33.9},
        '95.3mm x 31.8mm': {width: 95.3, height: 31.8},
        '60.3mm x 31.8mm': {width: 60.3, height: 31.8},
        '44.5mm x 31.8mm': {width: 44.5, height: 31.8},
        '177.8mm x 25.4mm': {width: 177.8, height: 25.4},
        '101.6mm x 25.4mm': {width: 101.6, height: 25.4},
        '76.2mm x 25.4mm': {width: 76.2, height: 25.4},
        '66.7mm x 25.4mm': {width: 66.7, height: 25.4},
        '63.5mm x 25.4mm': {width: 63.5, height: 25.4},
        '50.8mm x 25.4mm': {width: 50.8, height: 25.4},
        '38.1mm x 25.4mm': {width: 38.1, height: 25.4},
        '87.3mm x 23.8mm': {width: 87.3, height: 23.8},
        '88.9mm x 19.1mm': {width: 88.9, height: 19.1},
        '57.2mm x 19.1mm': {width: 57.2, height: 19.1},
        '87.3mm x 16.9mm': {width: 87.3, height: 16.9},
        '44.5mm x 16.9mm': {width: 44.5, height: 16.9},
        '76.2mm x 15.9mm': {width: 76.2, height: 15.9},
        '44.5mm x 12.7mm': {width: 44.5, height: 12.7},
    },
    [AveryLabelShapes.square]: {
        '203.2mm x 203.2mm': {width: 203.2, height: 203.2},
        '101.6mm x 101.6mm': {width: 101.6, height: 101.6},
        '76.2mm x 76.2mm': {width: 76.2, height: 76.2},
        // Default Square Size
        '69.9mm x 69.9mm': {width: 69.9, height: 69.9},
        '63.5mm x 63.5mm': {width: 63.5, height: 63.5},
        '54mm x 54mm': {width: 54, height: 54},
        '50.8mm x 50.8mm': {width: 50.8, height: 50.8},
        '38.1mm x 38.1mm': {width: 38.1, height: 38.1},
        '25.4mm x 25.4mm': {width: 25.4, height: 25.4},
        '19.1mm x 19.1mm': {width: 19.1, height: 19.1}
    },
    [AveryLabelShapes.zebra]: {
        '101.6mm x 152.4mm': {width: 101.6, height: 152.4},
        '63.5mm x 101.6mm': {width: 63.5, height: 101.6},
        '101.6mm x 31.75mm': {width: 101.6, height: 31.75},
    }
}

/**
 * The available types of object fit for image elements of the application.
 */
export enum ImageObjectFits {
    contain = 'contain',
    fill = 'fill',
    cover = 'cover',
}

/**
 * The available directions for the line elements of the application.
 */
export enum LineDirections {
    horizontal = 'horizontal',
    vertical = 'vertical',
}


/**
 * The available styles for the line elements of the application.
 */
export enum LineStyles {
    dotted = 'dotted',
    dashed = 'dashed',
    solid = 'solid',
    double = 'double',
    ridge = 'ridge',
    groove = 'groove',
}
