import {extendTheme as extendJoyTheme} from "@mui/joy/styles";
import {
    AppFontFamilyTheme,
    AppSpecificThemeScalesTheme,
    DarkAppSpecificPaletteTheme,
    LightAppSpecificPaletteTheme,
    ThemePrefix,
    withThemePrefix
} from "../shared";
import {KeyboardArrowDown} from "@mui/icons-material";
import {checkboxClasses, inputClasses, optionClasses, selectClasses} from "@mui/joy";


const joyTheme = extendJoyTheme({
    cssVarPrefix: ThemePrefix,
    colorSchemes: {
        light: {
            palette: {
                primary: {},
                appSpecific: LightAppSpecificPaletteTheme,
                divider: `#444444`,
            },
        },
        dark: {
            palette: {
                primary: {},
                appSpecific: DarkAppSpecificPaletteTheme,
                divider: `#444444`,
            },
        },
    },
    appSpecific: AppSpecificThemeScalesTheme,
    fontFamily: AppFontFamilyTheme,
    radius: {
        sm: '3px',
        md: '5px',
    },
    components: {
        JoyMenu: {
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    fontWeight: theme.vars.fontWeight.md,
                    backgroundColor: theme.vars.palette.background.level1,
                    borderRadius: theme.vars.radius.xs,
                }),
            },
        },
        JoyTypography: {
            defaultProps: {
                level: "body3",
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    ...(ownerState.level === 'body3' && {
                        color: theme.typography.body1.color,
                    }),
                    ...(ownerState.level === 'body2' && {
                        color: theme.typography.body1.color,
                    })
                }),
            },
        },
        JoyListItem: {
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                    '&:active': {
                        backgroundColor: theme.vars.palette.neutral.plainActiveBg,
                    },
                }),
            }
        },
        JoySelect: {
            defaultProps: {
                size: 'sm',
                variant: 'soft',
                indicator: <KeyboardArrowDown/>,
                color: 'neutral',
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                    ...(ownerState.variant === 'soft' && {
                        [`& .${selectClasses.button}`]: {
                            fontSize: theme.fontSize.xs,
                            fontWeight: theme.fontWeight.lg,
                        },

                        [`&:hover`]: {
                            boxShadow: `inset 0 0 0 1px ${theme.vars.palette.divider}`,
                            [`& .${selectClasses.startDecorator}`]: {
                                color: theme.vars.palette.text.primary,
                            },
                        },
                        [`&.${selectClasses.expanded}`]: {
                            boxShadow: `inset 0 0 0 2px ${theme.vars.palette.primary['400']}`,
                        },
                        [`&:hover, &.${selectClasses.expanded}`]: {
                            [`${withThemePrefix}palette-${ownerState.color}-${ownerState.variant}HoverBg`]: 'transparent',
                        },
                        [`&.${selectClasses.disabled}`]: {
                            color: `var(${withThemePrefix}palette-${ownerState.color}-${ownerState.variant}Color)`,
                            backgroundColor: 'transparent',
                            [`& .${selectClasses.indicator}`]: {
                                visibility: 'hidden',
                            },
                        },
                    })
                }),
                listbox: ({ownerState, theme}) => ({
                    ...(ownerState.variant === 'soft' && {
                        [`& .${optionClasses.root}`]: {
                            [`&.${optionClasses.highlighted}, &.${optionClasses.selected}`]: {
                                backgroundColor: 'transparent !important',
                            },
                        },
                    })
                }),
            },
        },
        JoyInput: {
            defaultProps: {
                size: 'sm',
                variant: 'soft',
                color: 'neutral',
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    'input[type=number]': {
                        // Firefox
                        MozAppearance: 'textfield',
                    },
                    ...(ownerState.variant === 'soft' && {
                        fontSize: theme.fontSize.xs,
                        [`& .${inputClasses.input}`]: {
                            fontWeight: theme.fontWeight.lg,
                        },
                        [`& .${inputClasses.startDecorator}`]: {
                            minWidth: 15.38,
                            fontWeight: theme.fontWeight.xl3,
                        },
                        [`& .${inputClasses.endDecorator}`]: {
                            minWidth: 15.38,
                            fontWeight: theme.fontWeight.lg,
                            color: theme.palette.text.tertiary,
                        },
                        [`&:hover`]: {
                            boxShadow: `inset 0 0 0 1px ${theme.vars.palette.divider}`,
                            [`& .${inputClasses.startDecorator}`]: {
                                color: theme.vars.palette.text.primary,
                            },
                            [`& .${inputClasses.endDecorator}`]: {
                                color: theme.vars.palette.text.primary,
                            },
                        },
                        [`&.${inputClasses.focused}`]: {
                            boxShadow: `inset 0 0 0 2px ${theme.vars.palette.primary['400']}`,
                            [`&:before`]: {
                                boxShadow: 'none',
                            },
                        },
                        [`&:hover, &.${inputClasses.focused}`]: {
                            [`${withThemePrefix}palette-${ownerState.color}-${ownerState.variant}HoverBg`]: 'transparent',
                        },
                        [`&.${inputClasses.disabled}`]: {
                            color: `var(${withThemePrefix}palette-${ownerState.color}-${ownerState.variant}Color)`,
                            backgroundColor: 'transparent',
                        },
                    })
                })
            },
        },
        JoyCheckbox: {
            defaultProps: {
                size: 'sm',
                variant: 'soft',
                color: 'neutral',
            },
            styleOverrides: {
                root: ({ownerState, theme}) => ({
                    ...(ownerState.variant === 'soft' && {
                        '--Icon-fontSize': '0.7rem',
                        height: 28,
                        [`& .${checkboxClasses.label}`]: {
                            fontSize: theme.fontSize.xs,
                            fontWeight: theme.fontWeight.md,
                        },
                        [`& .${checkboxClasses.checkbox}`]: {
                            ...(ownerState.checked && {
                                color: theme.vars.palette.common.white,
                                backgroundColor: theme.vars.palette.primary['400'],

                            }),
                        },
                        [`&:hover .${checkboxClasses.checkbox}`]: {
                            ...(ownerState.checked && {
                                color: theme.vars.palette.common.white,
                                backgroundColor: theme.vars.palette.primary['400'],
                            }),
                        },
                        [`&.${checkboxClasses.disabled}`]: {
                            color: theme.vars.palette.neutral['400'],
                            backgroundColor: 'transparent',
                            [`& .${checkboxClasses.checkbox}`]: {
                                ...(ownerState.checked && {
                                    color: theme.vars.palette.common.white,
                                    backgroundColor: 'transparent',
                                }),
                                ...(!ownerState.checked && {
                                    color: theme.vars.palette.neutral['400'],
                                    backgroundColor: theme.vars.palette.neutral['700'],
                                }),
                            },
                        },
                        'svg': {
                            width: 'initial',
                            height: 'initial',
                        },
                    }),
                }),
            },
        },
    },
});

export default joyTheme;
