import Utils from "./utils";
import {ReverseMap} from "../../types/in-app";
import {KeyboardEventKeys} from "../constants/enums";

export enum KeyboardShortcutKeys {
    quickActions = "quickActions",
    saveOnCloud = "saveOnCloud",
    saveLocalCopy = "saveLocalCopy",
    loadLocalCopy = "loadLocalCopy",
    togglePagesPanel = "togglePagesPanel",
    togglePropertiesPanel = "togglePropertiesPanel",
    newTextElement = "newTextElement",
    newTimeElement = "newTimeElement",
    newPageInfoElement = "newPageInfoElement",
    newBarcodeElement = "newBarcodeElement",
    newContainerElement = "newContainerElement",
    newAveryLabelElement = "newAveryLabelElement",
    newImageElement = "newImageElement",
    newLineElement = "newLineElement",
    newGridElement = "newGridElement",
    zoomIn = "zoomIn",
    zoomOut = "zoomOut",
    zoomTo100 = "zoomTo100",
    newPage = "newPage",
    keyboardShortcuts = "keyboardShortcuts",
}

export type KeyboardShortcutKeyNames = ReverseMap<typeof KeyboardShortcutKeys>

export interface KeyboardShortcut {
    shortcut: string,
    keysArray: Array<string>,
    applicable: (e: KeyboardEvent) => boolean,
}

/**
 * This interface hosts all the available shortcuts of the application.
 */
class KeyboardShortcutsService {
    private static readonly osSpecs = Utils.getOsSpecs();
    public static readonly shouldUseMeta = this.osSpecs.isLinux || this.osSpecs.isMac || this.osSpecs.isIOS;
    public static readonly pressedKeys: Array<string> = [];
    private static cachedShortcuts: Record<KeyboardShortcutKeyNames, KeyboardShortcut>;
    private static modifierKeys: Array<KeyboardEventKeys> = [KeyboardEventKeys.meta, KeyboardEventKeys.control, KeyboardEventKeys.shift, KeyboardEventKeys.alt];
    private static eventKeys = Object.values(KeyboardEventKeys);

    /**
     * Fetches the list of available shortcuts in this application.
     */
    public static get shortcuts() {
        if (this.cachedShortcuts) {
            return this.cachedShortcuts;
        }
        this.cachedShortcuts = {
            [KeyboardShortcutKeys.quickActions]: this.generateShortcut([KeyboardEventKeys.meta, '/']),
            [KeyboardShortcutKeys.saveOnCloud]: this.generateShortcut([KeyboardEventKeys.meta, KeyboardEventKeys.shift, 'S']),
            [KeyboardShortcutKeys.saveLocalCopy]: this.generateShortcut([KeyboardEventKeys.shift, KeyboardEventKeys.alt, 'S']),
            [KeyboardShortcutKeys.loadLocalCopy]: this.generateShortcut([KeyboardEventKeys.shift, KeyboardEventKeys.alt, 'L']),
            [KeyboardShortcutKeys.togglePagesPanel]: this.generateShortcut([KeyboardEventKeys.shift, 'S', 'L']),
            [KeyboardShortcutKeys.togglePropertiesPanel]: this.generateShortcut([KeyboardEventKeys.shift, 'S', 'R']),
            [KeyboardShortcutKeys.newTextElement]: this.generateShortcut([KeyboardEventKeys.shift, 'T']),
            [KeyboardShortcutKeys.newTimeElement]: this.generateShortcut([KeyboardEventKeys.shift, 'T', 'I']),
            [KeyboardShortcutKeys.newPageInfoElement]: this.generateShortcut([KeyboardEventKeys.shift, 'P', 'I']),
            [KeyboardShortcutKeys.newBarcodeElement]: this.generateShortcut([KeyboardEventKeys.shift, 'B', 'C']),
            [KeyboardShortcutKeys.newContainerElement]: this.generateShortcut([KeyboardEventKeys.shift, 'F']),
            [KeyboardShortcutKeys.newAveryLabelElement]: this.generateShortcut([KeyboardEventKeys.shift, 'A', "L"]),
            [KeyboardShortcutKeys.newImageElement]: this.generateShortcut([KeyboardEventKeys.shift, "I"]),
            [KeyboardShortcutKeys.newLineElement]: this.generateShortcut([KeyboardEventKeys.shift, "L"]),
            [KeyboardShortcutKeys.newGridElement]: this.generateShortcut([KeyboardEventKeys.shift, KeyboardEventKeys.alt, "G"]),
            [KeyboardShortcutKeys.zoomIn]: this.generateShortcut([KeyboardEventKeys.meta, {'=': KeyboardEventKeys.add}]),
            [KeyboardShortcutKeys.zoomOut]: this.generateShortcut([KeyboardEventKeys.meta, KeyboardEventKeys.subtract]),
            [KeyboardShortcutKeys.zoomTo100]: this.generateShortcut([KeyboardEventKeys.meta, KeyboardEventKeys.zero]),
            [KeyboardShortcutKeys.newPage]: this.generateShortcut([KeyboardEventKeys.shift, 'P']),
            [KeyboardShortcutKeys.keyboardShortcuts]: this.generateShortcut([KeyboardEventKeys.shift, KeyboardEventKeys.control, {'/': '?'}]),
        }
        return this.cachedShortcuts;
    }

    /**
     * Resets the pressed keys cache of this service.
     */
    public static resetPressedKeys() {
        this.pressedKeys.splice(0, this.pressedKeys.length);
    }

    /**
     * Fetches the keyboard symbols based on the current OS of the system.
     */
    public static get keyboardSymbols() {
        const res = {
            [KeyboardEventKeys.meta]: '',
            [KeyboardEventKeys.alt]: '⌥',
            [KeyboardEventKeys.capsLock]: '⇪',
            [KeyboardEventKeys.shift]: '⇧',
            [KeyboardEventKeys.control]: '⌃',
        }
        if (this.shouldUseMeta) {
            res[KeyboardEventKeys.meta] = '⌘';
        } else {
            res[KeyboardEventKeys.meta] = '⊞';
        }
        return res;
    }

    /**
     * Fetches all the available variants of the given key.
     * @param key   the key to find all the variants of.
     */
    public static getAllVariationsOfKey(key: KeyboardEventKeys | string): (KeyboardEventKeys | string)[] {
        const variants = [
            [KeyboardEventKeys.add, KeyboardEventKeys.addOld],
            [KeyboardEventKeys.decimal, KeyboardEventKeys.decimalOld],
            [KeyboardEventKeys.delete, KeyboardEventKeys.deleteOld],
            [KeyboardEventKeys.divide, KeyboardEventKeys.divideOld],
            [KeyboardEventKeys.multiply, KeyboardEventKeys.multiplyOld],
            [KeyboardEventKeys.separator, KeyboardEventKeys.separatorOld],
            [KeyboardEventKeys.subtract, KeyboardEventKeys.subtractOld],
        ];
        return variants.find(multiple => multiple.includes(key as KeyboardEventKeys)) ?? [key];
    }

    /**
     * Listens for any [keydown] and [keyup] events in the given root element and mutates the [pressedKeys] list of this service.
     *
     * * The mutated list is used by the shortcuts' applicability method to determine whether they are applicable or not.
     * @param rootElement   the element to which the event listeners are attached to.
     */
    public static listenForKeyPresses(rootElement: HTMLElement) {
        rootElement.addEventListener('keydown', (e) => {
            if (this.ignoreKeyboardEvent(e))
                return;
            Utils.modifyEvent(e);
            if (!this.pressedKeys.includes(e.key) && !this.modifierKeys.includes(e.key as KeyboardEventKeys))
                this.pressedKeys.push(...this.getAllVariationsOfKey(e.key));
        }, false)
        rootElement.addEventListener('keyup', (e) => {
            if (this.ignoreKeyboardEvent(e))
                return;
            Utils.modifyEvent(e);
            if (this.pressedKeys.includes(e.key)) {
                for (const variant of this.getAllVariationsOfKey(e.key)) {
                    this.pressedKeys.splice(this.pressedKeys.findIndex(k => k === variant), 1);
                }
            }
            if (e.key === KeyboardEventKeys.meta)
                this.pressedKeys.splice(0, this.pressedKeys.length);
        }, false)
    }

    /**
     * Determines whether the given keyboard event should be ignored and its [key] not to affect to this service's [pressedKeys] property.
     *
     * * if the target is an input element, we ignore it
     * * if the target has listbox as its role, we ignore it.
     * @param e     the keyboard event.
     */
    private static ignoreKeyboardEvent(e: KeyboardEvent): boolean {
        if ((e.target instanceof HTMLInputElement)) {
            return true;
        }
        if ((e.target instanceof HTMLTextAreaElement)) {
            return true;
        }
        const target = e.target as HTMLElement & { role: string };
        if (target?.role === 'listbox')
            return true;
        return false;
    }

    /**
     * Generates a shortcut for the provided keys array.
     *
     * * The applicable method of this shortcut must be used to determine whether this shortcut should be activated.
     * @param keys  the list of keys for which this shortcut is to be created.
     */
    private static generateShortcut(keys: Array<KeyboardEventKeys | string | Record<string, string>>): KeyboardShortcut {
        const shortcut = keys
            .map(key => typeof key === 'object' ? Object.values(key)[0] : key)
            .map(key => this.keyConversionMap[key] ?? key)
            .map(key => (this.keyboardSymbols as Record<string, KeyboardEventKeys>)[key] ?? key).join('')

        const keyArray = keys
            .map(key => typeof key === 'object' ? Object.keys(key)[0] : key)
            .map(key => this.keyConversionMap[key] ?? key)

        return {
            shortcut: shortcut,
            keysArray: keyArray.map(key => (this.keyboardSymbols as Record<string, KeyboardEventKeys>)[key] ?? key),
            applicable: (e) => this.isShortcutApplicable(e, keyArray),
        }
    }

    /**
     * Fetches the mapping of the key conversions.
     * This map is used to convert any key to another based on the constraints of the OS.
     */
    private static get keyConversionMap() {
        const res: Record<string, KeyboardEventKeys> = {};
        if (!this.shouldUseMeta) {
            res[KeyboardEventKeys.meta] = KeyboardEventKeys.control;
        }
        return res;
    }

    /**
     * Determines if the shortcut is applicable for execution.
     *
     *
     * @param e                     the keyboard event that has been emitted.
     * @param keyArray              the key sequence of this shortcut.
     */
    private static isShortcutApplicable(
        e: KeyboardEvent,
        keyArray: KeyboardEventKeys[],
    ) {
        // empty shortcuts do not apply
        if (!keyArray.length)
            return false;
        // if the shortcut contains modifier keys and the event does not contain them, not applicable
        for (const modifierKey of this.modifierKeys) {
            if (keyArray.includes(modifierKey) && !e.getModifierState(modifierKey))
                return false
        }

        const pressedKeys = Array.from(new Set([...this.pressedKeys, e.key]).values());
        for (let key of keyArray.filter(e => !this.modifierKeys.includes(e))) {
            if (!this.eventKeys.includes(key as KeyboardEventKeys)) {
                // keys that are not in KeyboardEventKeys are the language specific keys like alphabet, so we ignore casing.
                if (!pressedKeys.map(e => e.toLowerCase()).includes(key.toLowerCase()))
                    return false;
            } else if (!pressedKeys.includes(key)) {
                // these are the keys that are in the KeyboardEventKeys, so they have the same value in all languages
                return false
            }
        }

        // for a shortcut to be applicable, exactly the same number of keys must be pressed as the key sequence of the shortcut
        const keyArrayLength = keyArray
            .filter(e => !this.modifierKeys.includes(e))
            .reduce<string[]>((a, e) => [
                ...a,
                ...this.getAllVariationsOfKey(e)
            ], [])
            .length;

        return this.pressedKeys.length === keyArrayLength;
    }
}

export default KeyboardShortcutsService;
