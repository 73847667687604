import React, {useCallback, useLayoutEffect} from "react";
import EnvService from "../../../core/services/env-service";
import Utils from "../../../core/services/utils";
import {useLocation} from "react-router-dom";
import AppRoutes from "../../../core/models/static/routes/app-routes";
import {matchPath} from "react-router";

const AppInfoManager = () => {
    const location = useLocation();
    /**
     * Sets the applications' title based on the given location.
     * @type {function(Location): void}
     */
    const setApplicationTitle = useCallback((location) => {
        const appName = EnvService.title + ' - ';
        if (matchPath({path: AppRoutes.landing, end: true}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Not Found'});
        if (matchPath({path: AppRoutes.public.error.notFound, end: true}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Not Found'});
        if (matchPath({path: AppRoutes.public.error.accessDenied, end: true}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Access Denied'});
        if (matchPath({path: AppRoutes.public.error.serverError, end: true}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Server Error'});
        if (matchPath({path: AppRoutes.public.template}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Edit Template'});
        if (matchPath({path: AppRoutes.public.print}, location.pathname))
            return Utils.setAppInfo({title: appName + 'Print Template'});
    }, [])

    /**
     * Sets the applications' description based on the given location.
     * @type {function(Location): void}
     */
    const setApplicationDescription = useCallback((location) => {
    }, [])

    /**
     * With each change in the location of the URL:
     * - changes the application title to match the current location.
     */
    useLayoutEffect(() => {
        setApplicationTitle(location);
    }, [location, setApplicationTitle])

    /**
     * With each change in the location of the URL:
     * - changes the application description to match the current location.
     */
    useLayoutEffect(() => {
        setApplicationDescription(location);
    }, [setApplicationDescription, location])

    return (<></>);
}

export default AppInfoManager;
