import React, {useCallback, useMemo, useState} from "react";
import {PointerLockContext, PointerLockControllerContext} from "../../../hooks/use-pointer-lock";
import PointerLockCursor from "../curosr";

const PointerLockProvider = ({children}) => {
    const [state, setState] = useState({});

    /**
     * Sets the lock state of the pointer locks
     * @type {IPointerLockMutator}
     */
    const setLock = useCallback((options) => {
        setState(prevState => ({
            ...prevState,
            ...options,
        }))
    }, []);

    /**@type {IPointerLockControllerContext}*/
    const controller = useMemo(() => ({
        setLock: setLock,
    }), [setLock])

    return (
        <>
            <PointerLockControllerContext.Provider value={controller}>
                <PointerLockContext.Provider value={state}>
                    {children}
                    <PointerLockCursor/>
                </PointerLockContext.Provider>
            </PointerLockControllerContext.Provider>
        </>
    )
}

export default PointerLockProvider;
