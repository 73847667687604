import {generatePath} from "react-router-dom";
import {Params} from "react-router";

/**
 * The map of all the available AppRoutes in the system
 */
class AppRoutes {
    /**
     * Base route
     */
    static landing = '/';
    /**
     * Test route.
     */
    static test = '/test';
    /**
     * Public Views
     */
    static public = {
        error: {
            _base: '/error/*',
            _relative: {
                notFound: 'not-found',
                accessDenied: 'access-denied',
                serverError: 'server-error',
            },
            notFound: '/error/not-found',
            accessDenied: '/error/access-denied',
            serverError: '/error/server-error',
        },
        template: '/template/:accessToken',
        print: '/print/:accessToken'
    };

    /**
     * Generates the path of the given AppRoutes in the system that include route parameters.
     * @param {string} route
     * @param {Record<string, string | number>} params
     * @return {string} the generated path
     */
    static generateRoute(route: string, params?: Params | undefined): string {
        return generatePath(route, params)
    }
}

export default AppRoutes;
