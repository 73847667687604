/**
 * Houses all the sizes used in this application.
 */
class Sizes {

    /**
     * Static sizes of the application panel.
     */
    static panel = {
        /**
         * Height of the toolbar
         */
        toolbarHeight: 48,
        /**
         * Width of the left-panel
         */
        leftPanelWidth: 280,
        /**
         * Width of the right-panel
         */
        rightPanelWidth: 240,
    }

    /**
     * Static sizes of the template-panel components of the application.
     */
    static templatePanel = {

        // ------------------- TOOLBAR -------------------

        /**
         * Height of the toolbar title input
         */
        toolbarTitleHeight: this.panel.toolbarHeight - 10,

        // ------------------- PAGES PANEL -------------------

        /**
         * min-width of the pages panel
         */
        pagesPanelMinWidth: this.panel.leftPanelWidth,
        /**
         * width of the pages panel
         */
        pagesPanelWidth: this.panel.leftPanelWidth,
        /**
         * max-width of the pages panel
         */
        pagesPanelMaxWidth: this.panel.leftPanelWidth * 1.2,

        /**
         * width of the properties panel
         */
        propertiesPanelWidth: this.panel.rightPanelWidth,

        /**
         * The padding of the page container.
         */
        pageContainerPadding: 50,

        /**
         * The height of the action in the pages panel header.
         */
        pagesPanelHeaderActionHeight: 40,
        /**
         * The height of the items used in the pages panel (i.e. elements, pages, page-sizes).
         */
        pagesPanelItemHeight: 32,
        /**
         * The height of the expanded section of the pages panel header.
         */
        pagesPanelHeaderExpandedSectionHeaderHeight: 40,

        /**
         * The minimum height of the pages section of the pages-panel section
         */
        pagesPanelHeaderPagesModeMinHeight: 40 + 12 + 32,
        /**
         * The maximum height of the pages section of the pages-panel section
         */
        pagesPanelHeaderPagesModeMaxHeight: 40 + 12 + (32 * 10),

        /**
         * The minimum height of the pageSizes section of the pages-panel section
         */
        pagesPanelHeaderPageSizesModeMinHeight: 40 + 12 + 32,
        /**
         * The maximum height of the pageSizes section of the pages-panel section
         */
        pagesPanelHeaderPageSizesModeMaxHeight: 40 + 12 + (32 * 11),
        /**
         * The width of the custom-page-size grid in the pages panel.
         */
        pagesPanelHeaderPageSizesModeCustomPageSizeWidth: 175,


        // ------------------- PROPERTIES PANEL -------------------

        /**
         * The height of the title in the properties panel header.
         */
        propertiesPanelHeaderTitleHeight: 40,
        /**
         * The height of the properties in the properties panel content.
         */
        propertiesPanelPropertyHeight: 30,

        // ------------------- CONTENT -------------------

        /**
         * width of the content when there is no page in the template (used for action items)
         */
        contentEmptyTemplateWidth: 400,
    }


    /**
     * Static sizes of the print-panel components of the application.
     */
    static printPanel = {

        // ------------------- TOOLBAR -------------------

        /**
         * Height of the toolbar title input
         */
        toolbarTitleHeight: this.panel.toolbarHeight,

        // ------------------- PAGES PANEL -------------------

        /**
         * min-width of the pages panel
         */
        pagesPanelMinWidth: this.panel.leftPanelWidth,
        /**
         * width of the pages panel
         */
        pagesPanelWidth: this.panel.leftPanelWidth,
        /**
         * max-width of the pages panel
         */
        pagesPanelMaxWidth: this.panel.leftPanelWidth,
        /**
         * The height in the pages panel header.
         */
        pagesPanelHeaderHeight: 40,
        /**
         * The height of the items used in the pages panel (i.e. elements, pages, page-sizes).
         */
        pagesPanelItemHeight: 32,
    }

    /**
     * Static sizes of the toast components of the application.
     */
    static toast = {
        minHeight: 32,
    }

}

export default Sizes;
