import {IAppSpecificPalette, IAppSpecificThemeScales} from "../../../types/declarations/mui-joy";
import TextFontUtils from "../../../core/services/utils/text-font";
import Sizes from "../../../core/models/static/sizes";

/**
 * The prefix of the theme options.
 */
export const ThemePrefix = ''

/**
 * The prefix of the theme options with the --. Specific for the variable names.
 */
export const withThemePrefix = '--'.concat(!ThemePrefix.length ? '' : ThemePrefix.concat('-'));


//    TODO: implement appSpecific palette colors for light theme
export const LightAppSpecificPaletteTheme: IAppSpecificPalette = {
    "panel": {
        'toolbar-background': '',
        'right-panel-background': '',
        'left-panel-background': '',
        'content-preview-background': '',
    },
    'template-panel': {},
    'keyboard-shortcuts-panel': {
        'background': '',
        'toggler-background': '',
    },
    'scroll-bar': {
        track: '',
        thumb: '',
    },
    'toast': {
        "text-color": '',
        color: '',
    },
    'kbd': {
        color: ``,
        background: '',
    },
}

export const DarkAppSpecificPaletteTheme: IAppSpecificPalette = {
    "panel": {
        'toolbar-background': `var(${withThemePrefix}palette-background-level1)`,
        'right-panel-background': `var(${withThemePrefix}palette-background-level1)`,
        'left-panel-background': `var(${withThemePrefix}palette-background-level1)`,
        'content-preview-background': `var(${withThemePrefix}palette-background-body)`,
    },
    'template-panel': {},
    'keyboard-shortcuts-panel': {
        'background': `var(${withThemePrefix}palette-background-level1)`,
        'toggler-background': `var(${withThemePrefix}palette-background-body)`,
    },
    'scroll-bar': {
        track: 'transparent',
        thumb: 'rgba(131, 137, 146)',
    },
    'toast': {
        "text-color": `var(${withThemePrefix}palette-common-white)`,
        "color": `var(${withThemePrefix}palette-background-level1)`,
    },
    'kbd': {
        color: `var(${withThemePrefix}palette-common-white)`,
        background: '#212529',
    },
}

export const AppFontFamilyTheme = {
    body: `${TextFontUtils.defaultFontFamily}, var(${withThemePrefix}fontFamily-fallback)`,
    display: `${TextFontUtils.defaultFontFamily}, var(${withThemePrefix}fontFamily-fallback)`,
    code: `Source Code Pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace`,
    fallback: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
}

export const AppSpecificThemeScalesTheme: IAppSpecificThemeScales = {
    'panel': {
        "toolbar-height": Sizes.panel.toolbarHeight,
        "left-panel-width": Sizes.panel.leftPanelWidth,
        "right-panel-width": Sizes.panel.rightPanelWidth,
    },
    "template-panel": {
        'toolbar-title-height': Sizes.templatePanel.toolbarTitleHeight,
        'pages-panel-item-height': Sizes.templatePanel.pagesPanelItemHeight,
        'pages-panel-header-expanded-section-height': Sizes.templatePanel.pagesPanelHeaderExpandedSectionHeaderHeight,
        'pages-panel-header-action-height': Sizes.templatePanel.pagesPanelHeaderActionHeight,
        'properties-panel-header-title-height': Sizes.templatePanel.propertiesPanelHeaderTitleHeight,
        'properties-panel-property-height': Sizes.templatePanel.propertiesPanelPropertyHeight,
        'content-empty-template-width': Sizes.templatePanel.contentEmptyTemplateWidth,
    },
    'print-panel': {
        'toolbar-title-height': Sizes.printPanel.toolbarTitleHeight,
        'pages-panel-header-height': Sizes.printPanel.pagesPanelHeaderHeight,
        'pages-panel-item-height': Sizes.printPanel.pagesPanelItemHeight,
    },
    'toast': {
        "min-height": Sizes.toast.minHeight,
        "width": 'fit-content',
        "font-family": AppFontFamilyTheme.body,
    },
}
