import {deepmerge} from '@mui/utils';
import muiTheme from "./mui";
import joyTheme from "./joy";

/**
 * The default theme of the application.
 */
const theme = deepmerge(muiTheme, joyTheme);

export default theme;

