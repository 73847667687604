import ApiResponseCodes from "../../models/static/api-response-codes";
import Texts from "../../models/static/texts";
import moment from "moment/moment";
import {FileReadingOptions, OperatingSystems} from "../../constants/enums";
import {v4 as UUIDv4} from 'uuid';

import {BaseSyntheticEvent, ChangeEvent} from "react";
import {TinyColor, TinyColorOptions} from "@ctrl/tinycolor";
import {ColorInputValue} from "../../../types/in-app/color-text-field";
import accepts from "attr-accept";
import {Mode} from "@mui/system/cssVars/useCurrentColorScheme";
import {ImageNaturalSize, RectSize} from "../../../types/in-app";
import {ElementPoint, ElementSize} from "../../../types/in-app/element";

/**
 * The utility methods used in the application.
 */
class Utils {

    //              ########################### COMPARATORS ###################################

    /**
     * Compares two numbers
     * @param a {number}
     * @param b {number}
     */
    static numComparator(a: number, b: number): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1
    }

    /**
     * Compares two dates by converting them to moment objects and then comparing them
     * @param a {Date}
     * @param b {Date}
     */
    static dateComparator(a: Date, b: Date): number {
        const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
            if (a.isSame(b, 'ms')) return 0;
            if (a.isAfter(b, 'ms')) return 1;
            return -1;
        }
        return _momentComparator(moment(a), moment(b));
    }

    /**
     * Compares two strings.
     * @param a {string}
     * @param b {string}
     */
    static stringComparator(a: string, b: string): number {
        return a?.localeCompare(b);
    }

    /**
     * Compares two Booleans
     * @param a {boolean}
     * @param b {boolean}
     */
    static booleanComparator(a: boolean, b: boolean): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1;
    }


    //              ########################### UTILITIES ###################################

    /**
     * Determines if the provided instance's method is called by a subclass of the provided class.
     * @param instance  {Object}                The instance of the class.
     * @param Class     {ObjectConstructor}     The class.
     */
    static calledBySubclassOf(instance: Object, Class: { prototype: Object }): boolean {
        return Object.getPrototypeOf(instance) !== Class.prototype;
    }

    /**
     * Fetches the OS of the system with 90% accuracy.
     *
     * @author Vladyslav Turak
     * @see https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
     */
    private static getOS() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.mac;
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.ios;
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = OperatingSystems.windows;
        } else if (/Android/.test(userAgent)) {
            os = OperatingSystems.android;
        } else if (/Linux/.test(platform)) {
            os = OperatingSystems.linux;
        }
        return os;
    }

    /**
     * Fetches the information about the os of the user.
     */
    static getOsSpecs() {
        const os = this.getOS();
        const isMac = os === OperatingSystems.mac;
        const isIOS = os === OperatingSystems.ios;
        const isWindows = os === OperatingSystems.windows;
        const isAndroid = os === OperatingSystems.android;
        const isLinux = os === OperatingSystems.linux;
        return {
            os,
            isMac,
            isIOS,
            isWindows,
            isAndroid,
            isLinux,
        };
    }

    /**
     * Determines if two objects are equal.
     *
     * @param object1 {any}
     * @param object2 {any}
     * @return {boolean}
     */
    static deepEqual(object1: any, object2: any): boolean {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) return false;
            for (let i = 0; i < object1.length; i++) {
                if (!this.deepEqual(object1[i], object2[i])) return false;
            }
            return true;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) return false;
            const keys = Object.keys(object1);
            if (keys.length !== Object.keys(object2).length) return false;
            for (const key in object1) {
                if (!this.deepEqual(object1[key], object2[key])) return false;
            }
            return true;
        }
        // not array and not object, therefore must be primitive
        return object1 === object2;
    }

    /**
     * Determines if two objects are not equal.
     *
     * @param object1       {any}      The first object to compare.
     * @param object2       {any}      The second object to compare.
     * @param path          {string}   The path to the object.
     * @param inequalities  {any[]}    The array of inequalities.
     */
    static deepInequalityCatcher(object1: any, object2: any, path = '', inequalities: any[] = []): any[] {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) {
                inequalities.push(`${path}: ${JSON.stringify(object1)} != ${JSON.stringify(object2)}`);
                return inequalities;
            }
            for (let i = 0; i < object1.length; i++) {
                this.deepInequalityCatcher(object1[i], object2[i], `${path}[${i}]`, inequalities);
            }
            return inequalities;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) {
                inequalities.push(`${path}: ${JSON.stringify(object1)} != ${JSON.stringify(object2)}`);
                return inequalities;
            }
            const keys = Object.keys(object1);
            for (const key of keys) {
                this.deepInequalityCatcher(object1[key], object2[key], `${path}.${key}`, inequalities);
            }
            return inequalities;
        }
        // not array and not object, therefore must be primitive
        if (object1 !== object2) {
            inequalities.push(`${path}: ${JSON.stringify(object1)} != ${JSON.stringify(object2)}`);
            return inequalities;
        }
        return inequalities;
    }

    /**
     * Deep copy an acyclic *basic* Javascript object.  T
     *
     * * this method only handles basic scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
     * containing these.
     * * This method does *not* handle instances of other classes.
     * @param obj {any}
     */
    static deepCopy<T = any>(obj: T): T {
        let ret: any, key;
        let marker = '__deepCopy';

        // @ts-ignore
        if (obj && obj[marker])
            throw (new Error('attempted deep copy of cyclic object'));

        // @ts-ignore
        if (obj && obj.constructor === Object) {
            ret = {};
            // @ts-ignore
            obj[marker] = true;

            for (key in obj) {
                if (key === marker)
                    continue;

                // @ts-ignore
                ret[key] = this.deepCopy(obj[key]);
            }

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }

        // @ts-ignore
        if (obj && obj.constructor === Array) {
            ret = [];
            // @ts-ignore
            obj[marker] = true;

            // @ts-ignore
            for (key = 0; key < obj.length; key++)
                ret.push(this.deepCopy(obj[key]));

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }
        // It must be a primitive type -- just return it.
        return (obj);
    }

    /**
     * Performs a deep merge of objects and returns new object. Does not modify
     * objects (immutable) and merges arrays via concatenation.
     *
     * @param {...object} objects Objects to merge
     * @returns {object} New object with merged key/values
     */
    static deepMerge<T extends Record<string, any>>(...objects: T[]): T {
        const isObject = (obj: any): obj is Record<string, any> =>
            obj && typeof obj === 'object';

        return objects.reduce((prev: T, obj: T) => {
            for (const key of Object.keys(obj)) {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key as keyof T] = Array.from(new Set([...pVal, ...oVal])) as any;
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key as keyof T] = this.deepMerge(pVal, oVal) as any;
                } else {
                    prev[key as keyof T] = oVal as any;
                }
            }

            return prev;
        }, {} as T);
    }

    /**
     * Given an object, will flatten it and return all of its values as a single.
     *
     * if append, then for each of the values of the object, appends it to their values as a string
     * @param object {any}
     * @param append {string | null}
     */
    static flattenObjectAndReturnAsAList(object: any, append: string | null = null): string[] | any[] {
        const all = this.flattenObject(object);
        const res = [];
        for (const [key, value] of Object.entries(all)) {
            if (key) res.push(value);
        }
        if (append && append.length) return res.map(e => `${e}${append}`);
        return res;
    };

    /**
     * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
     * @param object {any}
     * @param parentKey {string | null}
     * @private
     */
    private static flatten(object: any, parentKey: string | null = null): any {
        return [].concat(
            ...Object.keys(object).map((key) => typeof object[key] === 'object'
                ? this.flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
                : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
            )
        );
    }

    /**
     * Creates a new flattened object off of the given object
     * @param object {any}
     */
    private static flattenObject(object: any) {
        return Object.assign({}, ...this.flatten(object))
    }

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.arrayBuffer): PromiseLike<ArrayBuffer | null>;
    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.json): PromiseLike<Record<string, any> | null>;
    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions.text | FileReadingOptions.dataUrl | FileReadingOptions.binaryString): PromiseLike<string | null>;
    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {string} as
     */
    static readFile(file: File | Blob, as: FileReadingOptions): PromiseLike<string | ArrayBuffer | Record<string, any> | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            switch (as) {
                case FileReadingOptions.text:
                case FileReadingOptions.json:
                    reader.readAsText(file);
                    break;
                case FileReadingOptions.dataUrl:
                    reader.readAsDataURL(file);
                    break;
                case FileReadingOptions.arrayBuffer:
                    reader.readAsArrayBuffer(file);
                    break;
                case FileReadingOptions.binaryString:
                    reader.readAsBinaryString(file);
                    break;
            }
            reader.onload = () => {
                if (as === FileReadingOptions.json) {
                    try {
                        resolve(JSON.parse(reader.result as string))
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    resolve(reader.result)
                }
            };
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Fetches the natural size of the image associated with the given imageUrl when rendered in the browser.
     * @param imageUrl
     * @param timeout
     */
    static getImageSize(imageUrl: string, timeout?: number): Promise<ImageNaturalSize | null> {
        return new Promise<ImageNaturalSize | null>((resolve) => {
            const image = new Image();
            image.src = imageUrl;
            if (timeout) {
                setTimeout(() => {
                    resolve(null);
                }, timeout)
            }
            image.onload = () => {
                resolve({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                })
            }
            image.onerror = () => {
                resolve(null)
            }
        })
    }

    /**
     * Creates a Unique Identifier in form of a string
     * @return {string}
     */
    static createUUId(): string {
        const uuid = UUIDv4();
        return `ID-${uuid}`
    }

    /**
     * Exports a .json file from the provided [json] object and the [fileTitle].
     * @param json
     * @param fileTitle
     */
    static exportJsonFile(json: Record<string, any>, fileTitle: string): void {
        const exportedFileName = fileTitle + '.json' || 'template.json';
        const text = JSON.stringify(json);
        const blob = new Blob([text], {type: 'data:application/json;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFileName);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                link.href = URL.createObjectURL(blob);
                link.download = exportedFileName;
                link.style.visibility = 'hidden';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    /**
     * Returns the given value with the maximum amount of decimals provided.
     * @param value
     * @param maxDecimals
     * @param returnType
     */
    static numberWithMaximumDecimals(
        value: string | number,
        maxDecimals: number = 2,
        returnType: "string" | "number" = 'string'
    ): number | string {
        const modulus = Number(value) % 1;
        if (modulus === 0) {
            return value;
        }
        const modulusString = value.toString();

        const numberOfDecimals = modulusString.split('.')?.length > 1
            ? modulusString.split('.')[1].length
            : 0;
        const valueWithCorrectDecimals = typeof value === 'string'
            ? parseFloat(value).toFixed(Math.min(numberOfDecimals, maxDecimals))
            : value.toFixed(Math.min(numberOfDecimals, maxDecimals));

        switch (returnType) {
            case 'string':
                return valueWithCorrectDecimals;
            case "number":
            default:
                return parseFloat(valueWithCorrectDecimals);
        }
    }

    /**
     * Given a string, forces a number value from it. If NAN, returns 0.
     * @param {string| number} val
     * @return {number}
     */
    static forceNumber(val: number | string): number {
        if (typeof val === 'number') return val;
        const _value = parseFloat(val ?? '0');
        return isNaN(_value) ? 0 : _value;
    }

    /**
     * Converts the given css values to pixel values.
     * @param {{name: string, value: any}[]} cssValues
     * @return {{name: string, value: number}[]}
     */
    static async calculateInPx(cssValues: { name: string, value: any }[]) {
        while (!window.Extensions.toPx) {
            await this.wait(50);
        }
        const result: { name: string, value: any }[] = [];
        if (!cssValues?.length) return result;
        const testElement = document.createElement('div');
        cssValues?.forEach((cssValue: { name: string, value: any }) => {
            testElement.style[cssValue.name as any] = cssValue.value;
        })
        document.documentElement.appendChild(testElement);
        cssValues.forEach((cssValue: { name: string, value: any }, index: number) => {
            result[index] = {
                name: cssValue.name,
                value: window.Extensions.toPx?.(
                    testElement,
                    testElement.style[cssValue.name as any],
                    cssValue.name
                )
            }
        })
        document.documentElement.removeChild(testElement);
        return result;
    }

    /**
     * Fetches the default Text message from the code of the api call response.
     * @param {number} code
     * @private
     */
    static getMessageFromResponseCode(code?: number) {
        switch (code) {
            //    HTTP Codes
            case ApiResponseCodes.badRequest:
                return Texts.badRequest;
            case ApiResponseCodes.unauthorized:
                return Texts.unauthorized;
            case ApiResponseCodes.forbiddenAccess:
                return Texts.forbiddenAccess;
            case ApiResponseCodes.notFound:
                return Texts.notFound;
            case ApiResponseCodes.serverError:
                return Texts.serverError;

            //    Custom Codes
            case ApiResponseCodes.requestFailed:
                return Texts.requestFailed;
            case ApiResponseCodes.serverNotResponded:
                return Texts.requestFailed;
            default:
                return;
        }
    }

    /**
     * Awaits for the specified time in milliseconds.
     * @param {number} milliseconds
     * @return {Promise<void>}
     */
    static async wait(milliseconds: number): Promise<void> {
        await new Promise(r => setTimeout(r, milliseconds));
    }

    /**
     * Modifies the given event with the given options.
     *
     * @param {Event} event
     * @param {boolean} preventDefault
     * @param {boolean} stopPropagation
     * @param {boolean} stopImmediatePropagation
     */
    static modifyEvent(
        event: Event | BaseSyntheticEvent,
        {
            preventDefault = true,
            stopPropagation = true,
            stopImmediatePropagation = false,
        } = {
            preventDefault: true,
            stopPropagation: true,
            stopImmediatePropagation: false,
        }
    ): void {
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
        if (stopImmediatePropagation && "stopImmediatePropagation" in event) {
            event.stopImmediatePropagation();
        }
    }

    /**
     * Fetches the safe tiny color from the provided color value.
     *
     * @param {ColorInputValue} color
     * @param {ColorInputValue} fallbackColor
     * @param {Partial<TinyColorOptions>} options
     */
    static getSafeTinyColor(
        color: ColorInputValue,
        fallbackColor: ColorInputValue,
        options?: Partial<TinyColorOptions>
    ): TinyColor {
        return new TinyColor(
            color === 'transparent' ? fallbackColor : color,
            options
        )
    }

    /**
     * Clamps the given value between the min and max numbers.
     *
     * @param {number} value
     * @param {number} min
     * @param {number} max
     */
    static clamp(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(value, max))
    }

    /**
     * Rounds the given number with a fraction digits between [minimumFractionDigits] and [maximumFractionDigits]
     *
     * @param value
     * @param minimumFractionDigits
     * @param maximumFractionDigits
     */
    static round(
        value: number,
        minimumFractionDigits?: number,
        maximumFractionDigits?: number
    ): number {
        const formattedValue = value.toLocaleString('en',
            {
                useGrouping: false,
                minimumFractionDigits,
                maximumFractionDigits
            }
        )
        return Number(formattedValue)
    }


    /**
     * Sets the application's title and description from given values.
     *
     * @param title
     * @param description
     */
    static setAppInfo({title, description}: { title?: string, description?: string } = {}): void {
        if (title) {
            document.title = title;
        }
        if (description) {
            $('meta[name="description"]').attr("content", description);
        }
    }

    /**
     * Prompts the user to select a file from the local machine.
     * @param acceptedTypes     the types of the files that are accepted in the prompted dialog.
     */
    static async promptFileSelection(...acceptedTypes: string[]): Promise<File | null> {
        return new Promise((resolve) => {
            if (!acceptedTypes.length) {
                return resolve(null);
            }
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = acceptedTypes.join();
            // @ts-ignore
            input.onchange = (e: ChangeEvent<HTMLInputElement>) => {
                if (!e.target?.files?.length)
                    return resolve(null);
                for (const file of e.target.files) {
                    if (accepts(file, acceptedTypes)) {
                        return resolve(e.target.files.item(0));
                    }
                }
                return resolve(null);
            }
            input.style.display = 'none';

            const button = document.createElement('button');
            button.onclick = (e) => input.click()
            input.style.display = 'none';
            document.body.appendChild(input);
            document.body.appendChild(button);
            button.click();
            document.body.removeChild(input);
            document.body.removeChild(button);
        })
    }

    /**
     * Fetches the duplicated value of the given [title].
     *
     * * appends a 'Copy' identifier to the end of the given title.
     * * if the title ends with a Copy [Number] then only adds to that number.
     * @param title
     */
    static getDuplicatedTitle(title: string) {
        let extension;
        let currentTitle = title.trim();
        const lastIndexOfCopy = currentTitle.lastIndexOf(' Copy');
        if (lastIndexOfCopy === -1) {
            extension = 'Copy';
        } else {
            const copyNumber = Number(currentTitle.slice(lastIndexOfCopy + 5));
            extension = `Copy ${copyNumber + 1}`;
            currentTitle = currentTitle.slice(0, lastIndexOfCopy)
        }
        return currentTitle + " " + extension;
    }

    /**
     * Fetches the property descriptor of the given [prop] found anywhere in the prototype chain of the given [obj].
     *
     * @param obj       the object that we will use to find the prop-descriptor associated with the given [prop]
     * @param prop      the property key for which the prop-descriptor of the [obj] is to be fetched.
     */
    static getOwnPropertyDescriptor<T = any>(obj: T, prop: PropertyKey): PropertyDescriptor | undefined {
        let descriptor;
        do {
            descriptor = Object.getOwnPropertyDescriptor(obj, prop);
        } while (!descriptor && (obj = Object.getPrototypeOf(obj)));
        return descriptor;
    }

    /**
     * Fetches the color theme mode of the browser.
     *
     * * Note that it is discouraged to use this method inside React component as the appropriate hook exists. This method only exists
     * in the services that are not bound to the UI.
     */
    static getColorThemeMode(): Mode {
        const mode = localStorage.getItem('joy-mode');
        switch (mode) {
            case 'dark':
                return 'dark'
            case 'light':
                return 'light';
            default:
                return 'system';
        }
    }

    /**
     * Fetches the number of rectangles that can fit into the provided container direction (vertical or horizontal) and their
     * margin in between them, so they can be evenly spaced in container.
     *
     * @param containerSide     the size of the container side
     * @param rectSide          the size of the rectangle side
     * @param containerPadding  the padding of the container
     * @param vertical          whether the rectangles are to be laid out vertically or horizontally
     */
    private static getRectangleCountToLayoutEvenlyInOneContainerDirection(
        containerSide: number,
        rectSide: number,
        containerPadding: number,
        vertical: boolean,
    ) {
        const formatter = (val: number) =>
            Math.floor(this.round(val, 3, 4) * 1000) / 1000;

        const _containerSide = containerSide - (containerPadding * 2);

        let num = Math.floor(_containerSide / rectSide);
        let availableMargin = formatter(_containerSide - (num * rectSide));
        let margin = formatter((availableMargin) / (num * 2));

        if (!vertical)
            while (margin < 1 && num > 1) {
                num -= 1;
                availableMargin = formatter(_containerSide - (num * rectSide));
                margin = formatter((availableMargin) / (num * 2));
            }
        else
            margin = 0;

        // since we are including the padding, for rects of size equal to container, the num will always be zero
        if (num === 0) {
            num = 1;
            margin = 0;
        }

        return {
            count: num,
            margin: Math.max(margin, 0),
            containerPadding: Math.max(availableMargin / 2, 0),
        };
    }

    /**
     * Fetches the number of rectangles that can fit into the provided container and their
     * margin in between them, so they can be evenly spaced in container.
     *
     * @param containerSize     the size of the container
     * @param rectSize          the size of the rectangle
     * @param containerPadding  the padding of the container
     */
    static layoutRectanglesEvenlyInContainer(
        containerSize: RectSize,
        rectSize: RectSize,
        containerPadding: number,
    ) {
        return {
            horizontal: this.getRectangleCountToLayoutEvenlyInOneContainerDirection(containerSize.width, rectSize.width, containerPadding, false),
            vertical: this.getRectangleCountToLayoutEvenlyInOneContainerDirection(containerSize.height, rectSize.height, containerPadding, true),
        };
    }

    /**
     * Fetches the number of rectangles that can fit into the provided container.
     * @param containerSize     the size of the container
     * @param rectSize          the size of the rectangle
     * @returns                 the number of rectangles that can fit into the provided container
     */
    static tightlyFitRectanglesInContainer(
        containerSize: RectSize,
        rectSize: RectSize,
    ): number {
        const horizontalCount = Math.floor(containerSize.width / rectSize.width);
        const verticalCount = Math.floor(containerSize.height / rectSize.height);

        return horizontalCount * verticalCount;
    }

    /**
     * Deeply removes all the instances of the provided property from the given entity.
     *
     * @param properties    the name of the property to be removed
     * @param entity        the entity to remove the property from
     */
    static deepRemoveProperties<T>(properties: Array<keyof T>, entity: T): Omit<T, keyof typeof properties> {
        if (!entity) {
            return entity;
        }
        if (Array.isArray(entity)) {
            return entity.map((e) => this.deepRemoveProperties(properties, e)) as T;
        }
        if (typeof entity === 'object') {
            for (const key of Object.keys(entity)) {
                if (properties.includes(key as keyof T)) {
                    delete entity[key as keyof T];
                } else {
                    const value = entity[key as keyof T];
                    entity[key as keyof T] = this.deepRemoveProperties(properties, value as T) as typeof value;
                }
            }
        }
        return entity;
    }

    /**
     * Deeply removes all the instances of the provided property from the given entity.
     *
     * @param property      the name of the property to be removed
     * @param entity        the entity to remove the property from
     */
    static deepRemoveProperty<T>(property: keyof T, entity: T): Omit<T, typeof property> {
        return this.deepRemoveProperties([property], entity);
    }

    /**
     * Does nothing.
     */
    static noop(): void {
        return (void 0);
    }

    /**
     * Swaps the axes of the provided array.
     * @param array    the array to swap the axes of
     * @returns        the array with swapped axes
     */
    static swapAxes<T = any>(array: Array<Array<T>>): Array<Array<T | null>> {
        const rows = array.length;
        const cols = Math.max(...array.map(row => row.length));

        // Create a new transposed array
        const transposedArray: Array<Array<T>> = [];
        for (let j = 0; j < cols; j++) {
            transposedArray[j] = [] as Array<T>;
            for (let i = 0; i < rows; i++) {
                transposedArray[j][i] = ((array[i][j] !== undefined) ? array[i][j] : null) as T;
            }
        }

        return transposedArray;
    }

    /**
     * Calculates the bounding box of the element based on the rotation value.
     *
     * @param position      the position of the element
     * @param size          the size of the element
     * @param centerPoint   the center point of the element
     * @param newRotation   the new value of the rotation property
     * @private
     */
    static calculateBoundingBox(position: ElementPoint, size: ElementSize, centerPoint: ElementPoint, newRotation: number): DOMRect {

        // Calculate the corners of the un-rotated rectangle
        const corners = [
            {x: centerPoint.x - size.width / 2, y: centerPoint.y - size.height / 2}, // Top-left
            {x: centerPoint.x + size.width / 2, y: centerPoint.y - size.height / 2}, // Top-right
            {x: centerPoint.x + size.width / 2, y: centerPoint.y + size.height / 2}, // Bottom-right
            {x: centerPoint.x - size.width / 2, y: centerPoint.y + size.height / 2}, // Bottom-left
        ];

        let rotatedCorners: Array<ElementPoint>;
        if (newRotation === 0) {
            rotatedCorners = corners;
        } else {
            // Rotate each corner by the given rotation
            rotatedCorners = corners.map(corner => this.rotatePoint(corner, centerPoint, newRotation));
        }

        // Find the bounding box
        const minX = Math.min(...rotatedCorners.map(e => e.x));
        const maxX = Math.max(...rotatedCorners.map(e => e.x));
        const minY = Math.min(...rotatedCorners.map(e => e.y));
        const maxY = Math.max(...rotatedCorners.map(e => e.y));

        return {
            left: minX,
            right: maxX,
            top: minY,
            bottom: maxY,
            width: maxX - minX,
            height: maxY - minY,
            y: minY,
            x: minX,
            toJSON: () => JSON.stringify({
                left: minX,
                right: maxX,
                top: minY,
                bottom: maxY,
                width: maxX - minX,
                height: maxY - minY,
                y: minY,
                x: minX
            })
        };
    }

    /**
     * Rotates the given point around the given origin by the given degrees.
     * @private
     * @param point     the point to rotate
     * @param center    the center to rotate around
     * @param angle     the angle to rotate by
     */
    static rotatePoint(point: ElementPoint, center: ElementPoint, angle: number) {
        const radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (point.x - center.x)) - (sin * (point.y - center.y)) + center.x,
            ny = (sin * (point.x - center.x)) + (cos * (point.y - center.y)) + center.y;
        return {x: nx, y: ny};
    }
}

export default Utils;
