class ApiResponseCodes {

    // ########################### HTTP SPECIFIC ###################################

    /**
     * When the given request is incomplete or wrong in the context of the server call
     */
    static readonly badRequest: number = 400;
    /**
     * When the user should log in to access the data from the server.
     */
    static readonly unauthorized: number = 401;
    /**
     * When the user does not have access to the requested data.
     */
    static readonly forbiddenAccess: number = 403;
    /**
     * When server was not found to communicate with
     */
    static readonly notFound: number = 404;
    /**
     * When server instance for the api call crashed or was terminated before any response could be given
     */
    static readonly serverError: number = 500;

    // ########################### CUSTOM ###################################

    /**
     * When the server call successfully was made but response was never returned.
     */
    static readonly aborted: number = 597;
    /**
     * When the server call failed due to a problem in request preparation.
     */
    static readonly requestFailed: number = 599;
    /**
     * When the server call successfully was made but response was never returned.
     */
    static readonly serverNotResponded: number = 598;
}

export default ApiResponseCodes;
