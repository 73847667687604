import React from "react";
import AppInfoManager from "./app-info-manager";

/**
 * The aggregator of all the application level middlewares.
 *
 * The middlewares responsibility is to inject behaviour
 * into the views or the entire application without any visible ui components.
 */
const ApplicationMiddlewares = () => {

    return (
        <>
            <AppInfoManager/>
        </>
    )
}

export default ApplicationMiddlewares;
