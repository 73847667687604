//    ***** CSS IMPORTS *******
import './assets/css/index.scss';
import "react-toastify/dist/ReactToastify.css";
import 'cropperjs/src/css/cropper.scss';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

//    ***** REST OF IMPORTS *******
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import setUpJQueryExtensions from "./core/services/extensions/jquery-extensions";
import setUpExtensions from "./core/services/extensions/window-extensions";
import Utils from "./core/services/utils";
import EnvService from "./core/services/env-service";
import KeyboardShortcutsService from "./core/services/keyboard-shortcuts";
import {registerPlugin} from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

/**
 * Initializes whether we need to force the webkit scrollbar or not.
 */
async function initializeScrollbar() {
    await new Promise((resolve) => document.addEventListener('DOMContentLoaded', resolve));
    // Exclude Edge which also includes 'Chrome' in UA
    const isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
    const chromeVersionArray = /Chrome\/([0-9]+)/.exec(navigator.userAgent);
    const chromeVersion = chromeVersionArray ? parseInt(chromeVersionArray[1], 10) : 0;
    const isModernChrome = isChrome && chromeVersion >= 121;

    document.body.classList.toggle('force-webkit-scrollbar', isModernChrome);
}

/**@type {HTMLElement}*/
export const RootElement = document.getElementById('root');
export const ReactRoot = ReactDOM.createRoot(RootElement)

setUpJQueryExtensions();
setUpExtensions();
reportWebVitals();
Utils.setAppInfo({title: EnvService.title, description: EnvService.description});
KeyboardShortcutsService.listenForKeyPresses(RootElement);
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
initializeScrollbar();


// STRICT MODE WILL INTENTIONALLY CALL USE_REDUCER AND USE_STATE's INITIAL METHODS MULTIPLE TIMES TO TEST THE PUTRIDITY OF SUCH
//  FUNCTIONS. IT ALSO CHECKS FOR THE PROBLEMS IN CONCURRENT MODE SO THE PERFORMANCE IS INTENTIONALLY WORSE. PLEASE BE AWARE!!!
// IF A DISPATCH OF A USE REDUCER THROWS, THE DISPATCHER AUTOMATICALLY RECALLS THE DISPATCH IN HOPE THAT THE ERROR IS RESOLVED
ReactRoot.render(
    // <StrictMode>
    <App/>
    // </StrictMode>
);


