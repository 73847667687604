import React, {useMemo} from 'react';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {Slide, toast, ToastContainer} from "react-toastify";
import ApplicationViews from "./ui/views";
import theme from "./ui/theme";
import {CssVarsProvider} from '@mui/joy/styles'
import ApplicationMiddlewares from "./ui/middlewares";
import PointerLockProvider from "./ui/components/pointer-lock/provider";
import {Provider as ReduxProvider} from 'react-redux';
import ReduxStore from "./ui/redux";

const App = () => {
    const history = useMemo(createBrowserHistory, [])

    return (
        <>
            <CssVarsProvider
                theme={theme}
                defaultMode={'dark'}
                enableColorScheme
            >
                <HistoryRouter history={history}>
                    <PointerLockProvider>
                        <ReduxProvider store={ReduxStore}>
                            <ApplicationViews/>
                            <ApplicationMiddlewares/>
                        </ReduxProvider>
                    </PointerLockProvider>
                </HistoryRouter>
                <ToastContainer
                    transition={Slide}
                    position={toast.POSITION.BOTTOM_CENTER}
                    closeButton={false}
                    hideProgressBar
                    newestOnTop
                    draggableDirection={'y'}
                    draggablePercent={10}
                    autoClose={5000}
                />
            </CssVarsProvider>
        </>
    );
}

export default App;
